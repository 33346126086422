.clickable-data-div {
    transition: background-color 2s ease, transform 0.4s ease;
    transform: scale(1.01);
    background-color: #2D3967 !important; 
}

.clickable-data-div p {
    color: white !important; 
}

.exercises-dialog > .p-dialog-content {
    padding: 0px !important;
}

.add-exercise-dialog-multi-select > .p-multiselect-label-container > .p-multiselect-label > .p-multiselect-token {
    border-radius: 10px;
    border: 1px solid #9EA4B9;
}

.add-exercise-dialog-multi-select:not(.p-disabled).p-focus {
    box-shadow: none;
}

.add-exercise-dialog-input-number > .p-inputnumber-input{
    border-radius: 10px;
}

.add-exercise-dialog-calendar > .p-inputtext {
    border-radius: 10px;
}