.patientHistory .p-card .p-card-body {
    padding: 0;
}

.controlPanel.p-card .p-card-body {
    padding: 0.5rem;
}

.planSwitchButton:hover {
    background: #E3506B !important;
    color: white !important;
    border: 1px solid #E3506B !important;
}

.prescription.p-sidebar .p-sidebar-header {
    background: #E0E3EE;
}

.prescription.p-sidebar .p-sidebar-content {
    padding: 0;
}