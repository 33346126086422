@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(245, 245, 245, 1);
  overflow-x: hidden;
}

code {
  font-family: 'Poppins', sans-serif !important;
}

:root {
  --font-family: 'Poppins', sans-serif !important;
}

/* Text input (global) CSS*/
.p-inputtext.p-variant-filled:enabled:hover {
  background-color: white;
}

.p-inputtext.p-variant-filled {
  background-color: white;
}

.p-inputtext:enabled:hover {
  border-color: #2D3967;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.1rem rgba(100, 173, 240, 0.3);
  border-color: #64ADF0;
}

.p-inputtext.p-invalid.p-component {
  border-color: #E3506B;
}



/* Button (global) CSS*/
.p-button:focus {
  box-shadow: none;
}



/* Dropdown (global) CSS*/
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 173, 240, 0.3);
  border-color: #64ADF0;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #2D3967;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #4b5563;
  background: #64ADF0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: rgba(100, 173, 240, 0.3);
}

.p-dropdown.p-invalid.p-component {
  border-color: #E3506B;
}


/* Checkbox (global) CSS*/
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #2D3967;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #2D3967;
  background: #2D3967;
}




/* OrderList (global) CSS*/
.p-orderlist .p-orderlist-controls {
  display: none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
  background: white;
}

.p-orderlist .p-orderlist-item {
  pointer-events: none;
}



/* Table (global) CSS*/
.p-datatable .p-datatable-header {
  background-color: #FFFFFF;
  border: none;
  padding: 0;
  font-weight: 100;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: #EBEDF2;
  color: #2D3967;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #727A99
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #727A99;
}

.p-datatable .p-paginator-bottom {
  border: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #2D3967;
  color: white;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #EBEDF2;
  color: #2D3967;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: #2D3967;

}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #EBEDF2;
  color: #2D3967;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #2D3967;
}

.p-datatable .p-datatable-tbody > tr {
  color: #2D3967;
}



/* TabView (global) CSS*/
.p-tabview .p-tabview-nav {
  justify-content: center;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #E3506B;
  color: #E3506B;
}



/* Card (global) CSS*/
.p-card .p-card-content {
  padding: 0;
}

.p-card {
  box-shadow: 0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1);
}


/* Slider (global) CSS*/
.p-slider.p-slider-horizontal {
  height: 0.5rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  display: none;
}

.p-slider .p-slider-range {
  background-color: #E3506B;
  border-radius: 10px;
}



/* Carusel (global) CSS*/
.p-carousel .p-carousel-indicators {
  display: none;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: #2D3967;
}



/* Radio Button (global) CSS*/
.p-radiobutton.p-highlight .p-radiobutton-box {
  border-color: #2D3967;
  background: #2D3967;
}

.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
  border-color: #2D3967;
}



/* Dialog (global) CSS*/
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #2D3967;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #2D3967;
}


/* Error Message (global) CSS*/
.p-inline-message.p-inline-message-error {
  background: white;
  border: none;
  border-width: 0px;
  color: #E3506B;
  justify-content: start;
  padding: 0;
}



/* ScrolBar (global) CSS*/
::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2D3967;
  border-radius: 10px;
  border: 0px
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2D3967;
}



/* Accordion (global) CSS*/
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  color: #2D3967;
}

.p-accordion-header-link .p-accordion-header-text {
  order: -1;
}


.p-accordion-header-link .p-icon {
  order: 1;
}

/* MultiSelect Items (global) CSS*/
.p-multiselect-panel>.p-multiselect-items-wrapper>.p-multiselect-items>.p-multiselect-item:has(.p-highlight) {
  background-color: rgba(45, 57, 103, 0.9);
  color: white;
}